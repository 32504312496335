import React from "react";
import Helmet from "react-helmet";
import { get } from "lodash";

// import favicon from '@sc/10mf/src/favicon.png';

export const SEOComponent: React.FC<{
  payload: any;
}> = ({ payload }) => {
  const parsedPayload = JSON.parse(unescape(payload));
  return (
    <>
      <Helmet
        title={unescape(get(parsedPayload, "title", ""))}
        meta={[
          { name: "description", content: get(parsedPayload, "description", "") },
          { name: "keywords", content: get(parsedPayload, "keywords", "") },
        ]}
      >
        <link rel="icon" type="image/png" href="https://sandcastleassets.s3.amazonaws.com/images/favicon.png" />
      </Helmet>
    </>
  );
};

export default SEOComponent;
