import { get } from "lodash";
import { convertFromRem } from "@sc/modules/editor/Builder/MobileResponsiveToggle/style";
import { MobileState } from "@sc/modules/editor/Builder/MobileResponsiveToggle/types";

export const generateFormComponentStyle = (properties) => {
  return {
    labelStyle: {
      fontSize: get(properties, "fontSize", "inherit"),
      fontFamily: get(properties, "fontFamily", "inherit"),
      color: get(properties, "color", "inherit"),
      textAlign: "left",
      padding: "2px 0",
    },
    descriptionStyle: {
      fontSize: get(properties, "fontSize", "inherit"),
      fontFamily: get(properties, "fontFamily", "inherit"),
      color: get(properties, "color", "inherit"),
      textAlign: "left",
      padding: "2px 0",
    },
    inputStyle: {
      fontSize: get(properties, "fontSize", "inherit"),
      fontFamily: get(properties, "fontFamily", "inherit"),
      padding: get(properties, "padding", "inherit"),
      height: get(properties, "height", "inherit"),
      width: "100%",
      color: get(properties, "color", "inherit"),
      textAlign: "left",
      backgroundColor: get(properties, "backgroundColor", "inherit"),
      boxShadow: get(properties, "boxShadow", "inherit"),
      borderWidth: get(properties, "borderWidth", "inherit"),
      borderStyle: get(properties, "borderStyle", "inherit"),
      borderRadius: get(properties, "borderRadius", "inherit"),
      borderColor: get(properties, "borderColor", "inherit"),
    },
    containerStyle: {
      display: "inline-block",
      textAlign: get(properties, "textAlign", "inherit"),
      width: get(properties, "width", "inherit"),
      marginBottom: get(properties, "margin", "inherit"),
      spacing: parseInt(Number(convertFromRem(get(properties, "margin", "inherit"), MobileState.FULLSCREEN)) / 8)
    },
    iconStyle: {
      fontSize: get(properties, "fontSize", "inherit"),
      color: get(properties, "color", "inherit"),
      padding: get(properties, "padding", "inherit"),
    },
  }
}