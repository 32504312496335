import { ComponentRenderHooks } from "@sc/plugins/index.d";
import * as React from "react";
import { get } from "lodash";
// import { EditorMode } from "@sc/modules/v2/Editor/types";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";
import { PluginProps, ListProps } from "./types";

// import { ListItem } from "..";
import ListItem from "../ListItem/live";
import { ComponentTypes } from "../../types";
import { IconTypes } from "../Icon";

export const List: React.FC<ListProps> = ({
  children,
  data = [],
  icon,
  iconSource,
  iconStyle,
  contentStyle,
  style,
}) => {
  if (children)
    return (
      <ul
        data-testid="WC-LIST-LIVE"
        style={{ listStyleType: "none", ...style }}
      >
        {children}
      </ul>
    );


  return (
    <ul
      data-testid="WC-LIST-LIVE"
      style={{ listStyleType: "none", padding: 0, ...style, margin: 0 }}
    >
      {data.map((itm) => {
        // const { style } = itm;
        return (
          <ListItem
            style={{
              ...get(itm, "style", style),
              marginBottom: get(style, "margin", "inherit")
              // marginBottom: 100
            }}
            iconStyle={{
              fontSize: get(style, "fontSize", "inherit"),
              color: get(style, "color", "inherit"),
              ...get(itm, "iconStyle", iconStyle),
              ...iconStyle
            }}
            iconSource={get(itm, "iconSource", iconSource)}
            contentStyle={get(itm, "contentStyle", contentStyle)}
            // icon={get(itm, "icon", icon)}
            icon={get(itm, "icon", icon) !== IconTypes.CancelPresentationTwoTone && get(itm, "icon", icon)}
          >
            <div style={{ display: "inline" }} dangerouslySetInnerHTML={{ __html: itm.caption.replace(/(?:\r\n|\r|\n)/g, '<br>') }} />
            {/* {JSON.stringify(style)} */}
          </ListItem>
        );
      })}
    </ul>
  );
};

const ListPlugin: React.FC<PluginProps> = ({ settings }) => {
  const properties = convertProperties(settings.properties);
  return (
    <V1ObjectWrapper settings={settings}>
      <List {...settings} properties={properties} />
    </V1ObjectWrapper>
  );
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.LIST
  ) {
    return [ListPlugin];
  }
};

export default List;
