import React from "react";
import MobileResponsiveness from "./MobileResponsiveness";
import { ComponentRenderHooks, ListItemsHooks } from "@sc/plugins/index.d";

export const onComponentRender = (hook, payload, { setMobileState }) => {
  if (hook.id === ComponentRenderHooks.PAGE_LOAD && setMobileState)
    return [<MobileResponsiveness key="mobile" onChange={setMobileState} />];
};

export default MobileResponsiveness;
