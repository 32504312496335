import { get, has, uniq, orderBy } from "lodash";
import { generateFontList } from "@sc/modules/editor/scripts";
import { EditorMode } from "@sc/modules/v2/Editor/types";

export const generateInlineStyle = ({ properties = {}, id }, mode = EditorMode.EDITOR) => {
  
  const buildFontSize = ({fontSize = false}) => {
    if (!fontSize) return "";

    if (Number.isInteger(fontSize)) return `font-size: ${fontSize}px;`
    else return `font-size: ${fontSize};`;
  }

  const buildFontFamily = ({fontFamily = false}) => {
    if (!fontFamily) return "";
    else return `font-family: ${fontFamily};`;
  }

  const buildLineHeight = ({lineHeight = false}) => {
    if (!lineHeight) return "";

    if (Number.isInteger(lineHeight)) return `line-height: ${lineHeight}px;`
    else return `line-height: ${lineHeight};`;
  }

  const buildColor = ({color = false}) => {
    if (!color) return "";
    else return `color: ${color};`;
  }

  const fontSize = buildFontSize(properties)
  const fontFamily = buildFontFamily(properties)
  const lineHeight = buildLineHeight(properties)
  const color = buildColor(properties)

  if (mode === EditorMode.EDITOR) return `#ed_${id} .mce-content-body p { ${fontSize}${fontFamily}${lineHeight}${color} }`;
  else return `#${id} p { ${fontSize}${fontFamily}${lineHeight}${color} }`;
};

export const generateGoogleFontList = (settings, pageContent) => {
  const googleFonts = generateFontList(pageContent);
  const currentFontsUsed = get(settings, "fontsUsed", []);
  const currentFontList = currentFontsUsed.map((arr) => get(arr, "fontFamily"));
  const googleFontList = orderBy(
    uniq([...currentFontList, ...googleFonts]).map((font) => ({
      name: font.split(":")[0],
      font: font.split(":")[0].toLowerCase(),
    })),
    "name"
  );

  return googleFontList;
};
