import * as React from "react";
import { get } from "lodash";
import Icon from "@sc/plugins/webcomponents/v2/Icon";
import { selectOptions } from "../../EditFormField/fieldNames"

/**
 * A component that shows a LIVE dropdown input field or tools for configuring that dropdown field's look, feel, and behavior
 */
export function LiveDropdownField(props) {
  const {
    labelStyle,
    descriptionStyle,
    iconStyle,
    inputStyle,
  } = props.styles;
  const [isDirty, setIsDirty] = React.useState(false);
  const { data = selectOptions.defaultOptions } = props;
  return (<div data-testid="FormBuilder-FormFields-DropdownField-LIVE" style={{
    textAlign: 'left'
  }}>
    {props.label && <div style={labelStyle}>{props.label}</div>}
    {props.description && <div style={descriptionStyle}>{props.description}</div>}
    {props.icon && <span style={{
      position: "absolute",
      paddingTop: get(iconStyle, 'padding', 0)
    }} ref={props.ref}>
      <Icon type={props.icon} style={{
        ...iconStyle,
        marginTop: 5,
        marginLeft: 1,
        paddingTop: 0
      }} />
    </span>}
    <div style={{
      ...inputStyle,
      // padding: parseInt(get(inputStyle, "padding", 5)) - 5,
      padding: 0,
      ...(props.icon ? {
        paddingLeft: `${get(props.iconDimensions, 'width')}px`
      } : {}), // ...icon ? { padding: `${inputPadding} ${inputPadding} ${inputPadding} ${get(iconDimensions, 'width')}px` } : {},
      // paddingRight: parseInt(get(inputStyle, "padding", 5))
      paddingRight: get(inputStyle, 'padding', 10)
    }}>
      <select placeholder={props.placeholder} style={{
        backgroundColor: "transparent",
        ...inputStyle,
        // padding: 0,
        border: "none"
      }}
        name={props.name}
        data-isDirty={isDirty}
      >
        {/* {data.map((itm, key) => <option key={key} // selected={itm.value === defaultValue}
          onChange={props.handleChange}>
          {itm.label}
        </option>)} */}
        {
          data.map(
            ({ name, value }, key) => {
              return <option key={key} value={name} onChange={props.handleChange}>{value}</option>
            }
          )}
      </select>
    </div>
  </div >);
}

export default LiveDropdownField
