import { ComponentRenderHooks } from "@sc/plugins/index.d";
import * as React from "react";
import _ from "lodash";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";
// import { EditorMode } from "@sc/modules/v2/Editor/types";
import LiveFormBuilder from "@sc/modules/v2/CRUD/FormBuilder/LiveFormBuilder";
// import FormBuilder from "@sc/modules/v2/CRUD/FormBuilder/FormBuilder";

import { PluginProps } from "./types";
import { ComponentTypes } from "../../types";

import { generateFormComponentStyle } from '../FormField/script'

/**
 * The version of the <FormBuilder /> component that shows in the editor canvas
 */
const FormBuilderPlugin: React.FC<PluginProps> = ({ settings }) => {
  const { data } = settings;
  const properties = convertProperties(settings.properties);

  return (
    <V1ObjectWrapper settings={settings}>
      {/* {JSON.stringify(settings)} */}
      <LiveFormBuilder
        data={data}
        style={generateFormComponentStyle(properties)}
        globalFieldStyle={generateFormComponentStyle(properties)}
      />
    </V1ObjectWrapper>
  );
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.FORM
  ) {
    return [FormBuilderPlugin];
  }
};

export default LiveFormBuilder;
