import { get, uniq, keys } from "lodash";
import { ISettings } from "@sc/modules/editor/Builder/WebComponent/types";

const objectDeepKeys = (obj) =>
  keys(obj)
    .filter((key) => obj[key] instanceof Object)
    .map((key) => objectDeepKeys(obj[key]).map((k) => `${key}.${k}`))
    .reduce((x, y) => x.concat(y), Object.keys(obj));

export const generateFontList: (content: ISettings[]) => string[] = (content) =>
  uniq(
    objectDeepKeys(content)
      .filter((itm) => itm.indexOf(".fontFamily") > -1)
      .map((key) => get(content, key))
  );

export const cancelActions = (e) => {
  // e.preventDefault();
  e.stopPropagation();
  return false;
};
