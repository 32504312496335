import { CSSProperties } from "react";
import {
  ActionTypes,
  ActionBehaviors,
} from "@sc/modules/editor/Builder/Properties/BuildingBlocks/Interactions/index.d";

export const CURSOR_ID: string | number = 1111;
export const BODY_ID: string | number = 2540;

export enum HoverCursorMethods {
  INSIDE_ITSELF,
  INSIDE_EMPTY_CONTAINER,
  BOTTOM_20_COLUMN,
  BOTTOM_20_OTHER,
  TOP_10_COLUMN,
  TOP_10_OTHER,
  LAST_ITEM_IN_CONTAINER,
  BEFORE_AFTER_MOUSE_POSITION,
}

export enum ComponentTypes {
  SECTION = "Section",
  GRID = "Grid",
  GRIDITEM = "GridItem",
  HEADLINE = "Headline",
  TEXT = "Text",
  VIDEO = "Video",
  IMAGE = "Image",
  CONTAINER = "Container",
  CURSOR = "Cursor",
  STEPPER = "Stepper",
  STEP = "Step",
  GROUP = "Group",
  SPACER = "Spacer",
  DIVIDER = "Divider",
  FORM = "Form",
  FORMFIELD = "Form Field",
  NAVIGATION = "Navigation",
  BUTTON = "AdvancedButton",
  TABLE = "Table",
  ANIMATION = "Animation",
  LIST = "List",
  LISTITEM = "ListItem",
  ORDERBUMP = "OrderBump",
  POPUP = "Popup",
  PROGRESS = "Progress",
  BLOCK = "Block",
  ICON = "Icon",
  REPEATER = "Repeater",
  ACCORDIAN = "Accordian",
  ACCORDIANITEM = "AccordianItem",
  GOOGLELOGIN = "GoogleLogin",
  FACEBOOKLOGIN = "FacebookLogin",
  COUNTDOWN = "Countdown",
  SHAREBUTTON = "ShareButton",
  ORDERSUMMARY = "OrderSummary",
  SHAPES = "Shapes",
  STACKS = "Stacks",
  SWITCH = "Switch",
  SMARTCONTAINER = "SmartContainer",
  FACEBOOKCOMMENTS = "FacebookComments",
  BODY = "Body",
  HTML = "HTML",
  TESTING = "TESTING",
  COLUMNS = "Columns",
  ROW = "Columns",
  COLUMN = "Column",
  PICTURE = "Picture",
  TABS = "Tabs",
  TAB = "Tab",
  CAROUSEL = "Carousel",
  CAROUSELITEM = "CarouselItem",
  NEWWEBCOMPONENT = "Testing",
  UNKNOWN = "Unknown",
}

export enum IStates {
  NORMAL = "normal",
  ACTIVE = "active",
  HOVER = "hover",
}

export type IComponentAction = {
  type: ActionTypes;
  behavior: ActionBehaviors;
  name: string;
  payload: false | any;
};

export type ISettings = {
  id?: string;
  type: ComponentTypes;
  html?: string;
  canHaveChildren?: boolean;
  preventDelete?: boolean;
  properties: CSSProperties;
  state?: IStates;
  parent?: string;
  fontsUsed?: string[];
  isLoading?: boolean; // see video component
  actions?: IComponentAction[];
};
