import * as React from "react";
import { EditorMode, EditorComponentProps } from "@sc/modules/v2/Editor/types";

import { IconTypes } from "../Icon/types";

export interface TabsProps {
  mode?: EditorMode;
  style?: React.CSSProperties;
  properties?: React.CSSProperties;
  value?: number;
  testId?: string;
}

export interface PluginProps extends EditorComponentProps {
  settings: TabsProps;
}

export type TabItemType = {
  id: string;
  label: string;
  description?: string;
  active: boolean;
  icon?: IconTypes;
};

export enum TabPositions {
  TOP = "top",
  BOTTOM = "bottom",
  LEFT = "left",
  RIGHT = "right",
}
