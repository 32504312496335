import { get, head, template, templateSettings, isEmpty } from 'lodash'

export const getInputVals = () => {
  const isBrowser = typeof window !== "undefined"
  if (!isBrowser) return null;

  const inputs = document.getElementsByTagName("input");
  const selects = document.getElementsByTagName("select");
  const selectArray = Array.prototype.slice.call(selects);
  const selectVals = selectArray.map(input => ({ name: input.name, value: input.value, id: input.id }));
  const inputArray = Array.prototype.slice.call(inputs);
  const inputVals = inputArray.map(input => ({ name: input.name, value: input.value, id: input.id }));
  return [...inputVals, ...selectVals];
};

export const getInputValue = (name = '', data = {}) => {
  if (data.findIndex(itm => itm.name === name) > -1) {
    return get(head(data.filter(itm => itm.name === name && itm.value !== "")), 'value', '')
  }
  return false;
}

export const deDupInputVals = (inputVals) => {
  let deDupedValues = []

  inputVals.forEach((itm) => {
    const { name } = itm;
    const filtered = inputVals.filter(i => i.name === name)
    if (filtered.length > 1) {
      const toUse = filtered.filter(({ value }) => !isEmpty(value) && value !== 'false')
      if (toUse.length) {
        deDupedValues.push(head(toUse))
      }
      else deDupedValues.push(itm)
    }
    else deDupedValues.push(itm)
  })

  return deDupedValues
}

export const parseValue = (value) => {
  const isBrowser = typeof window !== "undefined"
  if (!isBrowser) return null;

  // convert {url.xyz} into
  const inputVals = getInputVals();

  try {
    let obj = { url: {}, form: {} }
    let params = new URLSearchParams(location.search)
    params.forEach((val, key) => obj['url'][key] = val)
    inputVals.forEach(({ name, value }) => obj['form'][name] = value);

    // console.log(`Want to convert ${value} and passing`, obj, `through a template parser`)

    templateSettings.interpolate = /{{([\s\S]+?)}}/g;
    const compiled = template(value)
    return compiled(obj)

  } catch (err) {
    console.log(`Oops! There was an error:`, err)
    return value;
  }
}