import * as React from "react";
import { get, has, head } from "lodash";

import ReactCarousel, {
  arrowsPlugin,
  autoplayPlugin,
  Dots,
  slidesToShowPlugin,
} from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

import { ComponentRenderHooks } from "@sc/plugins/index.d";

import { CarouselProps, PluginProps } from "./types";
import { ComponentTypes } from "../../types";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";
import { switchCarouselItem } from "./scripts";
import { EditorMode } from "@sc/modules/v2/Editor/types";

import Icon from "../Icon";
import { IconSource, IconTypes } from "../Icon/types";

export const Carousel: React.FC<CarouselProps> = ({
  onSwitch = () => null,
  items = [],
  options = {},
  arrowOptions = {},
  numberOfSlides = 1,
  mode = EditorMode.LIVE,
  children = null,
}) => {
  let plugins = [];

  if (
    get(options, "arrows") &&
    !has(arrowOptions, "left") &&
    !has(arrowOptions, "right")
  )
    plugins.push("arrows");
  if (get(options, "clickToChange")) plugins.push("clickToChange");
  if (get(options, "centered")) plugins.push("centered");
  if (get(options, "autoPlay")) {
    plugins.push("infinite");
    plugins.push({
      resolve: autoplayPlugin,
      options: { interval: 2000 },
    });
  }
  if (get(options, "swipeable")) plugins.push("fastSwipe");

  plugins.push({
    resolve: slidesToShowPlugin,
    options: {
      numberOfSlides,
    },
  });

  if (
    get(options, "arrows") &&
    (has(arrowOptions, "left") || has(arrowOptions, "right"))
  ) {
    let arrowLeft = <div />;
    let arrowRight = <div />;
    let arrowLeftDisabled = <div />;
    let arrowRightDisabled = <div />;

    if (get(arrowOptions, "left.type") === "icon") {
      arrowLeft = (
        <Icon type={arrowOptions.left.value} source={IconSource.MATERIAL} />
      );
      arrowLeftDisabled = (
        <Icon
          type={arrowOptions.left.value}
          source={IconSource.MATERIAL}
          style={{ opacity: 0.5 }}
        />
      );
    }
    if (get(arrowOptions, "left.type") === "image") {
      arrowLeft = <img src={arrowOptions.left.value[0].preview} />;
      arrowLeftDisabled = (
        <img
          src={arrowOptions.left.value[0].preview}
          style={{ opacity: 0.5 }}
        />
      );
    }

    if (get(arrowOptions, "right.type") === "icon") {
      arrowRight = (
        <Icon type={arrowOptions.right.value} source={IconSource.MATERIAL} />
      );
      arrowRightDisabled = (
        <Icon
          type={arrowOptions.right.value}
          source={IconSource.MATERIAL}
          style={{ opacity: 0.5 }}
        />
      );
    }

    if (get(arrowOptions, "right.type") === "image") {
      arrowRight = <img src={arrowOptions.right.value[0].preview} />;
      arrowRightDisabled = (
        <img
          src={arrowOptions.right.value[0].preview}
          style={{ opacity: 0.5 }}
        />
      );
    }

    plugins.push({
      resolve: arrowsPlugin,
      options: {
        arrowLeft,
        arrowRight,
        arrowLeftDisabled,
        arrowRightDisabled,
        addArrowClickHandler: true,
      },
    });
  }

  const value = items.findIndex(({ active }) => active);

  return (
    <div data-testid="WC-CAROUSEL-LIVE">
      <ReactCarousel
        draggable={get(options, "swipeable", false)}
        plugins={plugins}
        value={value}
        onChange={(v) => {
          try {
            const itemId = items[v]["id"];
            console.log("rc2Ch", v, itemId, items);
            onSwitch(itemId);
          } catch (e) {}
        }}
      >
        {children}
      </ReactCarousel>
      {get(options, "dots") && (
        <Dots
          value={value}
          onChange={(e) => {
            try {
              const itemId = items[e]["id"];
              console.log("changed", e, itemId);
              onSwitch(itemId);
            } catch (e) {}
          }}
          number={items.length}
        />
      )}
    </div>
  );
};

export const CarouselItemContent: React.FC = ({ children }) => {
  return <div data-testid="WC-CAROUSELITEM-LIVE">{children}</div>;
};

const CarouselPlugin: React.FC<PluginProps> = ({
  settings,
  updateComponentSettings,
  children,
  mode,
}) => {
  const properties = convertProperties(settings.properties);

  return (
    <V1ObjectWrapper
      settings={settings}
      forceStyle={{
        width: get(properties, "width", "inherit"),
      }}
    >
      <Carousel
        {...settings}
        mode={mode}
        properties={properties}
        onSwitch={(carouselItemId) => {
          switchCarouselItem({
            settings,
            updateComponentSettings,
            carouselItemId,
          });
        }}
      >
        {children}
      </Carousel>
    </V1ObjectWrapper>
  );
};

const CarouselItemPlugin: React.FC<PluginProps> = ({
  settings,
  pageContent,
  children,
}) => {
  const parentCarousel = head(
    pageContent.filter(({ id }) => id === settings.parent)
  );
  const isCarouselItemActive =
    get(parentCarousel, "items", []).findIndex(
      ({ id, active = false }) => id === get(settings, "itemId") && active
    ) > -1;

  if (isCarouselItemActive) {
    // show only the content associated with the active carousel item
    return <CarouselItemContent>{children}</CarouselItemContent>;
  }

  return null;
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.CAROUSEL
  ) {
    return [CarouselPlugin];
  }

  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.CAROUSELITEM
  ) {
    return [CarouselItemPlugin];
  }
};

export default Carousel;
