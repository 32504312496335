import { ComponentRenderHooks } from "@sc/plugins/index.d";
import * as React from "react";
import { get } from "lodash";
// import { EditorMode } from "@sc/modules/v2/Editor/types";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";
import { PluginProps, NavigationProps } from "./types";

// import { ListItem } from "..";
import { ComponentTypes } from "../../types";
// import { IconTypes } from "../Icon";
import { Links } from "./Links";
import { EditorMode } from "@sc/modules/v2/Editor/types";

export const Navigation: React.FC<NavigationProps> = ({
  children,
  data = [],
  // icon,
  // iconSource,
  // iconStyle = {},
  linkStyle = {},
  // contentStyle = {},
  layoutStyle = {},
  style = {},
  properties = {},
}) => {
  if (children)
    return (
      <ul
        data-testid="WC-NAVIGATION-LIVE"
        style={{ listStyleType: "none", display: "inline-block", ...style }}
      >
        {children}
      </ul>
    );

  // display: "inline-block",
  return (
    <div
      data-testid="WC-NAVIGATION-LIVE"
      style={{
        listStyleType: "none",
        padding: 0,
        display: "inline-block",
        ...style,
      }}
    >
      <Links
        items={data.map((itm) => ({ ...itm, type: itm.linkDisplayType }))}
        linkStyle={{
          ...linkStyle,
          fontSize: get(properties, 'fontSize', 'inherit'),
          fontFamily: get(properties, 'fontFamily', 'inherit'),
          color: get(properties, 'color', 'inherit'),
        }}
        layoutStyle={layoutStyle}
        mode={EditorMode.LIVE}
      />
    </div>
  );
};

const NavigationPlugin: React.FC<PluginProps> = ({ settings }) => {
  const properties = convertProperties(settings.properties);
  return (
    <V1ObjectWrapper settings={settings}>
      <Navigation {...settings} properties={properties} />
    </V1ObjectWrapper>
  );
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.NAVIGATION
  ) {
    return [NavigationPlugin];
  }
};

export default Navigation;
