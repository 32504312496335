import * as React from "react";
import { range } from "lodash";
import { Grid } from "@material-ui/core";
import { LinearScaleDisplayType } from "../types";
// import { ButtonSliderGroup } from "@sc/modules/v2/Properties/components/groups/";

/**
 * shows a list of numbered options
 */
export function LiveLinearScaleField(props) {
  const {
    labelStyle,
    descriptionStyle
  } = props.styles;

  const {
    displayType,
    min,
    max,
  } = props;

  return (<div data-testid="FormBuilder-FormFields-LinearScaleField-LIVE">
    {props.label && <div style={labelStyle}>{props.label}</div>}
    {props.description && <div style={descriptionStyle}>{props.description}</div>}
    {displayType === LinearScaleDisplayType.RADIO && <Grid container justify="space-around" direction="row">
      {range(min, max + 1).map((itm, key) => <Grid item key={key} style={{
        marginTop: 10,
        textAlign: "center",
        padding: 10
      }}>
        <div style={{
          padding: "15px 0"
        }}>{itm}</div>
        <input key={key} type="radio" onClick={props.handleChange} />
      </Grid>)}
    </Grid>}
    {displayType === LinearScaleDisplayType.SLIDER && <div>
      {/* <ButtonSliderGroup maxValue={5} minValue={0} showSlider label="Button Slider Label" onValueChange={props.handleChange} __testSliderValue={3} /> */}
    </div>}
  </div>);
}

export default LiveLinearScaleField