import * as React from "react";
import { get } from "lodash";
import Icon from "@sc/plugins/webcomponents/v2/Icon";

/**
 * A component that shows a LIVE textarea input field or tools for configuring that textarea field's look, feel, and behavior
 */
export function LiveTextAreaField(props) {

  const {
    labelStyle,
    descriptionStyle,
    iconStyle,
    inputStyle,
  } = props.styles;

  const { onBlur } = props;

  return (<div data-testid="FormBuilder-FormFields-TextAreaField-LIVE">
    {props.label && <div style={labelStyle}>{props.label}</div>}
    {props.description && <div style={descriptionStyle}>{props.description}</div>}
    {props.icon && <span style={{
      position: "absolute",
      paddingTop: get(iconStyle, 'padding', 0)
    }} ref={props.ref}>
      <Icon type={props.icon} style={{
        ...iconStyle,
        marginTop: 5,
        marginLeft: 1,
        paddingTop: 0
      }} />
    </span>}
    <textarea placeholder={props.placeholder} value={props.defaultValue} onChange={props.handleChange} onBlur={onBlur} style={{
      ...inputStyle,
      ...(props.icon ? {
        padding: `${props.inputPadding} ${props.inputPadding} ${props.inputPadding} ${get(props.iconDimensions, 'width')}px`
      } : {})
    }} />
  </div>);
}

export default LiveTextAreaField