import * as React from "react";
import { get, head } from 'lodash';

import {
  ActionBehaviors,
  ActionTypes,
} from "@sc/modules/editor/Builder/Properties/BuildingBlocks/Interactions/index.d";

import { ComponentRenderHooks } from "@sc/plugins/index.d";

import { TabsProps, PluginProps, TabPositions } from "./types";
import { ComponentTypes } from "../../types";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";
import Icon from "../Icon";
import { IconSource, IconTypes } from "../Icon/types";
import { switchTab } from "./scripts";
import { EditorMode } from "@sc/modules/v2/Editor/types";

export const Tabs: React.FC<TabsProps> = ({
  style = {},
  tabStyle = {},
  tabHoverStyle = {},
  tabActiveStyle = {},
  tabVisibility = true,
  tabPosition = TabPositions.TOP,
  actions = [],
  properties = {},
  onSwitch = () => null,
  items = [],
  mode = EditorMode.LIVE,
  children = null,
}) => {

  let tabDisplay; let flexDirection;

  const handleSwitch = onSwitch;
  const eachTabStyle = {
    ...style,
    ...properties,
    margin: 0,
    // marginRight: (tabPosition === TabPositions.LEFT || tabPosition === TabPositions.RIGHT) ? style.margin || properties.margin || 0 : 0,
    // marginTop: (tabPosition === TabPositions.TOP) ? style.margin || properties.margin || 0 : 0,
    // marginBottom: (tabPosition === TabPositions.BOTTOM) ? style.margin || properties.margin || 0 : 0,
    ...(tabPosition === TabPositions.BOTTOM || tabPosition === TabPositions.TOP) ? { marginRight: style.margin || properties.margin || 0 } : {},
    ...(tabPosition === TabPositions.LEFT || tabPosition === TabPositions.RIGHT) ? { marginTop: style.margin || properties.margin || 0 } : {},
    cursor: 'pointer',
    width: "unset",
    ...tabStyle,
  }

  const LI = ({ id, label, description = "", icon = "", active }) => {
    const [isHoveringOnTab, setIsHoveringOnTab] = React.useState<boolean>(false);

    const thisTabStyle = {
      ...eachTabStyle,
      ...isHoveringOnTab ? { ...tabHoverStyle } : {},
      ...active ? { ...tabActiveStyle } : {},
      position: 'relative',
      display: 'block',
    }

    const action = head(actions)

    return (
      <li
        style={thisTabStyle}
        onClick={() => {
          if (mode === EditorMode.EDITOR) handleSwitch(id);
          if (get(action, 'behavior') === ActionBehaviors.CLICK && get(action, 'type') === ActionTypes.SWITCH_TABS) handleSwitch(id)
        }}
        onMouseEnter={() => { if (!active) setIsHoveringOnTab(true) }}
        onMouseLeave={() => { if (!active) setIsHoveringOnTab(false) }}
      >
        {(icon || label) && (
          <div style={{ display: "flex" }}>
            {Boolean(icon.length && icon !== IconTypes.CancelPresentationTwoTone) && (<div style={{ marginRight: 5 }}><Icon type={icon} source={IconSource.MATERIAL} /></div>)}
            <div>{label}</div>
          </div>)
        }
        {Boolean(description.length) && <div>{description}</div>}
      </li>
    )
  }

  switch (tabPosition) {
    case TabPositions.TOP:
      tabDisplay = "flex";
      flexDirection = "column";
      break;
    case TabPositions.BOTTOM:
      tabDisplay = "flex";
      flexDirection = "column-reverse";
      break;
    case TabPositions.LEFT:
      tabDisplay = "block";
      flexDirection = "row";
      break;
    case TabPositions.RIGHT:
      tabDisplay = "block";
      flexDirection = "row-reverse";
      break;
  }

  return (
    <div data-testid="WC-TABS-LIVE">
      <div style={{ display: "flex", flexDirection }}>
        {tabVisibility && (
          <ul style={{ ...tabStyle, display: tabDisplay }}>
            {items.map(itm => <LI {...itm} />)}
            {/* {(mode === EditorMode.EDITOR) && <li style={{ position: 'relative' }} onClick={() => alert("Add New Tab!")}>(+)</li>} */}
          </ul>
        )}
        <div style={{ flex: "auto" }}>{children}</div>
      </div>
    </div>
  );
};

export const TabContent: React.FC = ({ children }) => {
  return (
    <data data-testid="WC-TAB-LIVE" style={{}}>
      {children}
    </data>
  )
}

const TabsPlugin: React.FC<PluginProps> = ({ settings, updateComponentSettings, children, mode }) => {

  const properties = convertProperties(settings.properties);

  return (
    <V1ObjectWrapper settings={settings} forceStyle={{
      width: get(properties, 'width', 'inherit'),
    }}>
      <Tabs {...settings} mode={mode} properties={properties} onSwitch={(tabId) => { switchTab({ settings, updateComponentSettings, tabId }) }}>
        {children}
      </Tabs>
    </V1ObjectWrapper>
  );
};

const TabPlugin: React.FC<PluginProps> = ({ settings, pageContent, children }) => {

  const parentTab = head(pageContent.filter(({ id }) => id === settings.parent));
  const isTabActive = get(parentTab, 'items', []).findIndex(({ id, active = false }) => id === get(settings, 'itemId') && active) > -1

  if (isTabActive) { // show only the content associated with the active tab
    return (
      <TabContent>{children}</TabContent>
    )
  }

  return null;
}

export const onComponentRender = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT && payload.type === ComponentTypes.TABS) {
    return [TabsPlugin];
  }

  if (hook.id === ComponentRenderHooks.WEBCOMPONENT && payload.type === ComponentTypes.TAB) {
    return [TabPlugin];
  }
};

export default Tabs;
