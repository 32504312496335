import React from "react";
import { get } from "lodash";
import DangerousHTML from 'react-dangerous-html';

import { PageScriptsProps } from "./index.d";

export const PageScripts: React.FC<PageScriptsProps> = ({
  payload = {},
  alreadyParsed = false,
  type,
}) => {
  const parsedTrackingPayload = alreadyParsed ? payload : JSON.parse(unescape(payload));

  return <DangerousHTML html={get(parsedTrackingPayload, type, "")} />
};

export default PageScripts;
