import { fieldset } from "@sc/components/ui/theme"

export default {
  span: {
    cursor: "text",
    display: "inline-block"
  },
  fieldset,
  selectField: {
    padding: 10,
    color: "#666",
    border: "1px solid #DDD",
    borderRadius: 3
  },
  divider: {
    borderTop: "1px solid white",
    height: 1,
    borderBottom: "1px solid #CCC"
  },
  select: {
    // fontSize: 14,
    cursor: "default",
    WebkitAppearance: "none",
    MozAppearance: "none",
    border: "1px solid #ccc",
    borderRadius: 0,
    background:
      "url(https://image.flaticon.com/icons/svg/60/60995.svg) 97% / 8px no-repeat #fff"
  },
  coverObject: {
    width: "100%",
    zIndex: 50,
    position: "absolute",
    // background: 'green',
    height: "100%"
  }
};
