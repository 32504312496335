import React from "react";
import { get } from "lodash";
import reducers from "@sc/plugins/webcomponents/v2/Button/reducers";

const ouibounce =
  typeof window === "object" ? require("ouibounce") : (x, y) => false;

type ExitIntentProps = {
  payload: any;
  updateComponentSettings: () => void;
  getComponentSettings: () => void;
};

export const ExitIntent: React.FC<ExitIntentProps> = ({
  payload = "{}",
  updateComponentSettings = () => null,
  getComponentSettings = () => null,
}) => {
  // let payload = exitPayload;
  if (typeof payload === "string") payload = JSON.parse(payload);

  React.useEffect(() => {
    // console.log("The popup to show on exit is", get(payload, "id"), { payload, getComponentSettings });

    if (get(payload, "id")) {
      // Exit Popup
      ouibounce(false, {
        callback: () => {
          const id = get(payload, "id", false);
          if (id) {
            reducers([], {
              type: "OPEN_POPUP",
              payload: {
                showId: id,
              },
              updateComponentSettings,
              getComponentSettings,
            });
          }
        },
      });
    }
  }, []);

  return null;
};

export default ExitIntent;
