import { FormFieldTypes } from "../types";
import { LiveFormFieldProps } from "../LiveFormField/types";
import { FieldTypeOnChangePayload } from "./SelectFieldType/types";
import { IconTypes } from "@sc/plugins/webcomponents/v2/Icon";

export enum FieldNamePresets {
  CUSTOM = "custom",
  NAME = "name",
  EMAIL = "email",
  PASSWORD = "password",
  USERNAME = "username",
  COMPANY = "company",
  FIRSTNAME = "firstName",
  LASTNAME = "lastName",
  ADDRESS = "address",
  ADDRESS2 = "address2",
  CITY = "city",
  STATE = "state",
  COUNTRY = "country",
  ZIP = "zip",
  PHONE = "phone",
  CVV = "cvv",
  CARDTYPE = "cardType",
  CARDNUMBER = "cardNumber",
  EXPIRATIONMONTH = "expirationMonth",
  EXPIRATIONYEAR = "expirationYear",
  EXPIRATIONDATE = "expirationDate",
}

export const FieldNameIcons = {
  [FieldNamePresets.CUSTOM]: IconTypes.AlternateEmail,
  [FieldNamePresets.NAME]: IconTypes.AccountCircle,
  [FieldNamePresets.EMAIL]: IconTypes.Email,
  [FieldNamePresets.PASSWORD]: IconTypes.VpnKey,
  [FieldNamePresets.USERNAME]: IconTypes.VerifiedUser,
  [FieldNamePresets.COMPANY]: IconTypes.Business,
  [FieldNamePresets.FIRSTNAME]: IconTypes.Input,
  [FieldNamePresets.LASTNAME]: IconTypes.Input,
  [FieldNamePresets.ADDRESS]: IconTypes.Home,
  [FieldNamePresets.ADDRESS2]: IconTypes.Home,
  [FieldNamePresets.CITY]: IconTypes.LocationCity,
  [FieldNamePresets.STATE]: IconTypes.ArrowDropDownCircle,
  [FieldNamePresets.COUNTRY]: IconTypes.Flag,
  [FieldNamePresets.ZIP]: IconTypes.Input,
  [FieldNamePresets.PHONE]: IconTypes.Call,
  [FieldNamePresets.CVV]: IconTypes.Input,
  [FieldNamePresets.CARDTYPE]: IconTypes.Payment,
  [FieldNamePresets.CARDNUMBER]: IconTypes.CreditCard,
  [FieldNamePresets.EXPIRATIONMONTH]: IconTypes.ArrowDropDownCircle,
  [FieldNamePresets.EXPIRATIONYEAR]: IconTypes.ArrowDropDownCircle,
  [FieldNamePresets.EXPIRATIONDATE]: IconTypes.Today,
  [FormFieldTypes.TEXT]: IconTypes.Input,
  [FormFieldTypes.PASSWORD]: IconTypes.VpnKey,
  [FormFieldTypes.CHECKBOXES]: IconTypes.List,
  [FormFieldTypes.TEXTAREA]: IconTypes.Assignment,
  [FormFieldTypes.SELECT]: IconTypes.ArrowDropDownCircle,
  [FormFieldTypes.DATE]: IconTypes.Today,
  [FormFieldTypes.CONTENT]: IconTypes.Notes,
  [FormFieldTypes.NUMBER]: IconTypes.LooksOne,
  // [FormFieldTypes.CURRENCY]: IconTypes.MonetizationOn,
  [FormFieldTypes.DROPDOWN]: IconTypes.ArrowDropDownCircle,
  // [FormFieldTypes.MULTIPLECHOICE]: IconTypes.ToggleOn,
  // [FormFieldTypes.TYPOGRAPHY]: IconTypes.Notes,
  // [FormFieldTypes.FILEUPLOAD]: IconTypes.CloudUpload,
  // [FormFieldTypes.LINEARSCALE]: IconTypes.LinearScale,
};

export interface FieldNameTypes {
  label: string;
  fieldType: FormFieldTypes;
  attributes?: LiveFormFieldProps;
}

export interface NameValuePair {
  name: string | boolean;
  value: string;
}
export interface EditFormFieldProps {
  /**
   * A unique identifier for this component
   */

  id: string;

  /**
   * The type of form field being edited
   */
  type: FormFieldTypes | boolean;

  /**
   * The preset that was used for this form field
   */
  preset: FieldNamePresets | boolean;

  /**
   * The form field attributes to use for that form field (e.g. placeholder, value, etc.)
   */
  fieldData?: LiveFormFieldProps;

  /**
   * Whether or not to show the <LiveFormField /> as a preview
   */
  showPreview?: boolean;

  /**
   * Whether or not to wrap the settings in a grouped countainer (e.g. <fieldset />)
   */
  showFieldset?: boolean;

  /**
   * the list of items to use where there are multiple options needed (e.g. a dropdown menu, multiple choice array)
   */
  items?: any[];

  /**
   * Whether or not the component is showing in full, expanded view
   */
  isExpanded?: boolean;

  /**
   * The label/caption to show for the component
   */
  caption?: string;

  /**
   * The style to use on all form field
   */
  globalFieldStyle?: {
    labelStyle: React.CSSProperties;
    inputStyle: React.CSSProperties;
    containerStyle: React.CSSProperties;
    validationStyle: React.CSSProperties;
  };

  /**
   * Triggers when the payload has changed in some way
   */
  onChange?: FieldTypeOnChangePayload;

  /**
   * Triggers when a payload item has lost focus
   */
  onBlur?: FieldTypeOnChangePayload;

  /**
   * Triggers when the payload has changed in some way
   */
  onTypeChange?: FieldTypeOnChangePayload;

  /**
   * Triggers when the container has been clicked
   */
  onClick?: () => void;
}
