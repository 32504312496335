import { doUpsell, getInputVals, deDupInputVals, parseValue, sendData } from "@sc/modules/editor/Builder/Page/scripts";
import _ from "lodash";
import { google, outlook, office365, yahoo, ics } from 'calendar-link'

import { switchTab } from '@sc/plugins/webcomponents/v2/Tabs/scripts';
// import { getInputValue } from "@sc/modules/editor/Builder/Page/scripts/getInputValues";

const showButtonLoader = action => {
  if (_.has(action, 'updateComponentSettings')) {
    console.log({ action })
    if (typeof action.updateComponentSettings === "function") {
      action.updateComponentSettings(action.settings.id, {
        ...action.settings,
        // html: "Processing...",
        disabled: true,
        // oldHtml: action.settings.html
        caption1: "Processing...",
        oldCaption1: _.get(action, 'settings.caption1'),
      });
    }
  }
};

const newHideButtonLoader = action => {
  if (_.has(action, 'getComponentSettings')) {
    action.updateComponentSettings(action.settings.id, {
      ...action.settings,
      caption1: _.get(action.settings, 'caption1')
    })
  }
}

const hideButtonLoader = action => {
  console.log('hiding', { action })
  if (_.has(action, 'getComponentSettings')) {
    const oldSettings = action.getComponentSettings(action.settings.id);
    action.updateComponentSettings(action.settings.id, {
      ...action.settings,
      disabled: false,
      // html: _.get(oldSettings, "oldHtml", "Continue...")
      caption1: _.get(oldSettings, "caption1", "Continue...")
    });
  }
};

const showItem = (showId, getComponentSettings, updateComponentSettings, callback = () => null) => {

  const showIdSettings = getComponentSettings(showId);

  if (showId) {
    updateComponentSettings(showId, {
      ...showIdSettings,
      properties: {
        ..._.get(showIdSettings, "properties", false),
        display: _.get(showIdSettings, "properties.oldDisplay", "block"),
        // display: "block"
        mobile: {
          ..._.get(showIdSettings, "properties.mobile", false),
          fullscreen: {
            ..._.get(showIdSettings, "properties.mobile.fullscreen", false),
            display: _.get(showIdSettings, "properties.oldDisplay", "block")
          },
          desktop: {
            ..._.get(showIdSettings, "properties.mobile.desktop", false),
            display: _.get(showIdSettings, "properties.oldDisplay", "block")
          },
          tablet: {
            ..._.get(showIdSettings, "properties.mobile.tablet", false),
            display: _.get(showIdSettings, "properties.oldDisplay", "block")
          },
          smartphone: {
            ..._.get(showIdSettings, "properties.mobile.smartphone", false),
            display: _.get(showIdSettings, "properties.oldDisplay", "block")
          }
        }
      }
    }, callback);
  } else callback()

}

const hideItem = (hideId, getComponentSettings, updateComponentSettings, callback = () => null) => {

  if (getComponentSettings && updateComponentSettings) {
    const hideIdSettings = getComponentSettings(hideId);

    if (hideId) {
      updateComponentSettings(
        hideId,
        {
          ...hideIdSettings,
          properties: {
            ..._.get(hideIdSettings, "properties", false),
            display: "none",
            mobile: {
              ..._.get(hideIdSettings, "properties.mobile", false),
              fullscreen: {
                ..._.get(hideIdSettings, "properties.mobile.fullscreen", false),
                display: "none"
              },
              desktop: {
                ..._.get(hideIdSettings, "properties.mobile.desktop", false),
                display: "none"
              },
              tablet: {
                ..._.get(hideIdSettings, "properties.mobile.tablet", false),
                display: "none"
              },
              smartphone: {
                ..._.get(hideIdSettings, "properties.mobile.smartphone", false),
                display: "none"
              }
            }
          }
        },
        callback
      )
    } else callback()
  }
}

const isFormDataValid = (inputVals, settings) => {
  // console.log({ inputVals, settings })
  const { getComponentSettings = () => null } = settings;

  let proceed = true;

  inputVals.filter(({ value }) => value === "").every(({ id, name, value }) => {
    const setting = getComponentSettings(id);
    if (_.get(setting, 'attributes.isRequired')) {
      alert(`The ${_.get(setting, 'attributes.placeholder')} (${name}) field is required.`);
      proceed = false;
      return false;
    }
    console.log({ setting })
    return true;
  })

  return proceed;
}

export const triggerNextPage = (action) => {
  const isBrowser = typeof window !== "undefined"
  if (!isBrowser) return null;

  const url = _.get(_.head(document.getElementsByName("nxP")), "value");
  // console.log({ url });
  // const url = "http://www.tenminutefunnels.com"

  if (_.get(action, 'payload.forwardParams', false)) {
    window.location.href = url ? `/${url}${location.search}` : "#";
  } else window.location.href = url ? `/${url}` : "#";

}

export const submitForm = (action) => {
  const isBrowser = typeof window !== "undefined"
  if (!isBrowser) return null;

  const inputVals = getInputVals();
  const inputValsJson = JSON.stringify(inputVals);

  // check for required fields
  if (isFormDataValid(inputVals, action.settings)) {

    showButtonLoader(action);

    // send the form contents to the selected crm integration
    sendData(inputValsJson, (res = true) => {
      if (res) {
        let url = _.get(action, "payload.url", action.settings.href);
        if (action.payload.useTag) url = localStorage.getItem(url);
        // window.location.href = url;
        if (_.get(action, 'payload.forwardParams', false)) {
          window.location.href = `${url}${location.search}`
        } else window.location.href = url;
      }
    });

  }

}

export const triggerUpsell = (action) => {
  const isBrowser = typeof window !== "undefined"
  if (!isBrowser) return null;

  const url = action.settings.href;
  if (localStorage.hasOwnProperty("orderResponse")) {
    const orderResponse = JSON.parse(localStorage.orderResponse);
    const sendableOrderResponse = _.toPairs(orderResponse).map((i, k) => ({
      name: i[0],
      value: i[1]
    }));

    const inputVals = [...sendableOrderResponse, ...getInputVals()];
    const inputValsJson = JSON.stringify(inputVals);

    showButtonLoader(action);

    doUpsell(inputValsJson, (res = true) => {
      if (res) {
        window.location.href = url;
      } else {
        hideButtonLoader(action);
      }
    });
  } else {
    hideButtonLoader(action);
    alert("Sorry, we are unable to locate your previous order!");
  }
}

export const triggerPurchase = (action) => {
  const isBrowser = typeof window !== "undefined"
  if (!isBrowser) return null;

  const inputVals = [
    ...getInputVals(),
    {
      name: "purchaseType",
      value: _.get(action, "payload.purchaseType", "default")
    },
    { name: "shoppingCartItems", value: _.get(localStorage, "cart", "[]") }
  ];

  showButtonLoader(action);

  const inputValsJson = JSON.stringify(inputVals);
  const toCheck = inputVals.filter(
    itm => itm.name !== "emP" && itm.name !== "pmP" && itm.name !== "nxP"
  );

  if (toCheck.length) {
    // send the form contents to the selected crm integration
    sendData(inputValsJson, (res = true) => {
      if (res) {
        if (_.get(action, 'payload.forwardParams', false)) {
          window.location.href = `${action.settings.href}${location.search}`
        } else window.location.href = action.settings.href;
      } else {
        hideButtonLoader(action);
      }
    });
  } else {
    if (_.get(action, 'payload.forwardParams', false)) {
      window.location.href = `${action.settings.href}${location.search}`
    } else window.location.href = action.settings.href;
  }
}

export const goToUrl = (action) => {
  const isBrowser = typeof window !== "undefined"
  if (!isBrowser) return null;

  let url = _.get(action, "payload.url", action.settings.href);
  if (action.payload.useTag) url = localStorage.getItem(url);


  if (_.get(action, 'payload.forwardParams', false)) {
    window.location.href = `${url}${location.search}`
  } else window.location.href = url;
}

export const scrollToSection = (action) => {
  const isBrowser = typeof window !== "undefined"
  if (!isBrowser) return null;

  // console.log(action)
  window.location.href = `#${_.get(action, 'payload.sectionId')}`
}

export const downloadFile = (action) => {
  let url = _.get(action, "payload.url", false);
  if (url) {
    // alert(`Download File Now! ${url} ||| ${JSON.stringify(action)}`)
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.target = '_blank';
    anchor.download = url;
    anchor.click();
  }
}

export const triggerWebHook = async (action) => {
  const isBrowser = typeof window !== "undefined"
  if (!isBrowser) return null;

  const { payload } = action;
  const { request_method, request_url, url } = payload

  const myInputValsOriginal = getInputVals();
  const inputVals = deDupInputVals(myInputValsOriginal);
  console.log('done deDup', { myInputValsOriginal, inputVals })
  // check for required fields
  if (isFormDataValid(inputVals, action.settings)) {

    showButtonLoader(action);

    console.log("triggerWebHook", { request_method, request_url, inputVals });

    let body = _.get(payload, 'body', {})
    let headers = _.get(payload, 'headers', {})
    inputVals.forEach(({ name, value }) => {
      if (!_.isEmpty(name)) body[name] = value;
    })

    // parse values
    _.keys(body).forEach((k) => {
      // const key = _.keys(body)[k];
      body[k] = parseValue(body[k])
    })

    console.log({ body })

    // trigger fetch now
    try {

      let options = {
        method: request_method,
        headers: {
          "content-type": "application/json",
          ...headers,
        },
        // mode: 'no-cors'
      }

      let r_url = request_url

      if (request_method === "POST") {
        options.body = JSON.stringify(body);

      } else {
        r_url = `${request_url}?${Object.keys(body).map(key => key + '=' + body[key]).join('&')}`
      }

      console.log({ r_url, options })

      const response = await fetch(r_url, options)
      const res = await response.json();
      // console.log(res);

      if (response.status === 200) {

        if (_.has(payload, 'url')) {
          if (action.payload.useTag) url = localStorage.getItem(url);
          // window.location.href = url;

          if (_.get(action, 'payload.forwardParams', false)) {
            let params = new URLSearchParams(`${url}${location.search}`)
            _.keys(res).forEach(key => {
              params.set(key, res[key])
            })

            console.log('For passing along', { status: response.status }, `URL: ${url}${location.search}`, res, url, params.toString())

            window.location.href = `${url}?${params.toString()}` // also pass along the response too (e.g. session)
            newHideButtonLoader(action);

          } else {
            console.log('hiding')
            newHideButtonLoader(action);
            window.location.href = url;
          }
        }

      } else {
        console.log('response', response.status);
        newHideButtonLoader(action);
        alert("There was an error submitting your information.  Please try again later");
      }


    } catch (err) {
      console.log({ error: err })
      newHideButtonLoader(action);
      alert("There was an error submitting your information.  Please try again later")
    }

  }
}

export const showAndHide = (action) => {
  const { payload, updateComponentSettings, getComponentSettings } = action;
  const { hideId, showId } = payload;

  hideItem(hideId, getComponentSettings, updateComponentSettings, () => {
    showItem(showId, getComponentSettings, updateComponentSettings)
  })
}

export const openPopup = (action) => {
  // console.log({ action })
  const { payload, updateComponentSettings = () => null, getComponentSettings = () => null } = action;
  const { showId } = payload;
  if (showId) {
    const showIdSettings = getComponentSettings(showId);

    console.log({ action, showIdSettings, showId });
    updateComponentSettings(showId, {
      ...showIdSettings,
      properties: {
        ..._.get(showIdSettings, "properties", false),
        display: _.get(showIdSettings, "properties.oldDisplay", "block"),
        mobile: {
          ..._.get(showIdSettings, "properties.mobile", false),
          fullscreen: {
            ..._.get(showIdSettings, "properties.mobile.fullscreen", false),
            display: _.get(showIdSettings, "properties.oldDisplay", "block")
          },
          desktop: {
            ..._.get(showIdSettings, "properties.mobile.desktop", false),
            display: _.get(showIdSettings, "properties.oldDisplay", "block")
          },
          tablet: {
            ..._.get(showIdSettings, "properties.mobile.tablet", false),
            display: _.get(showIdSettings, "properties.oldDisplay", "block")
          },
          smartphone: {
            ..._.get(showIdSettings, "properties.mobile.smartphone", false),
            display: _.get(showIdSettings, "properties.oldDisplay", "block")
          }
        }
      }
    });
  }
}

export const hidePopup = (action) => {
  const { payload, updateComponentSettings, getComponentSettings } = action;
  const { hideId } = payload;
  const hideIdSettings = getComponentSettings(hideId);

  console.log({ action, hideIdSettings, hideId });

  updateComponentSettings(hideId, {
    ...hideIdSettings,
    properties: {
      ..._.get(hideIdSettings, "properties", false),
      display: "none",
      mobile: {
        ..._.get(hideIdSettings, "properties.mobile", false),
        fullscreen: {
          ..._.get(hideIdSettings, "properties.mobile.fullscreen", false),
          display: "none"
        },
        desktop: {
          ..._.get(hideIdSettings, "properties.mobile.desktop", false),
          display: "none"
        },
        tablet: {
          ..._.get(hideIdSettings, "properties.mobile.tablet", false),
          display: "none"
        },
        smartphone: {
          ..._.get(hideIdSettings, "properties.mobile.smartphone", false),
          display: "none"
        }
      }
    }
  });
}

export const createTag = (action) => {
  const { payload } = action;
  const { tagName, tagValue } = payload;
  localStorage.setItem(tagName, tagValue);
}

export const toggleAddRemoveCart = (action) => {

  const { payload, returnCallBack } = action;

  // get the current cart
  let currentCart = JSON.parse(localStorage.getItem('cart')) || []
  const { id } = payload;

  // check if the item already is in the cart
  const isInCart = currentCart.findIndex(itm => itm.id === id) > -1

  // if it is, remove it (and returnCallBack(false)).
  if (isInCart) {
    returnCallBack(false)
    localStorage.setItem('cart', JSON.stringify(currentCart.filter(itm => itm.id !== id)))
  }

  // if it isn't, add it (and returnCallBack(true)).
  else {
    returnCallBack(true)
    localStorage.setItem('cart', JSON.stringify([...currentCart, payload]))
  }


}

export const switchTabs = ({ payload = {}, getComponentSettings = () => null, updateComponentSettings = () => null }) => {
  const { tabId = "", tabItemId = "" } = payload;

  const settings = getComponentSettings(tabId);
  if (settings) switchTab({ settings, tabId: tabItemId, updateComponentSettings });
}

export const addToCalendar = ({ payload = {} }) => {
  const { href } = payload;

  const isBrowser = typeof window !== "undefined"
  if (!isBrowser) return null;

  if (href) window.open(href, "_blank");
  else window.location.href = "#";

}

export default {
  triggerNextPage,
  submitForm,
  triggerUpsell,
  triggerPurchase,
  goToUrl,
  downloadFile,
  showAndHide,
  openPopup,
  hidePopup,
  createTag,
  toggleAddRemoveCart,
  switchTabs,
  addToCalendar,
  triggerWebHook,
  scrollToSection
}