import { ComponentRenderHooks, ListItemsHooks } from "@sc/plugins/index.d";
import * as React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { lighten, makeStyles, withStyles } from "@material-ui/core/styles";
import { get } from "lodash";
import { ProgressProps, PluginProps } from "./types";
import { ComponentTypes } from "../../types";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";

export const Progress: React.FC<ProgressProps> = ({
  style = {},
  properties = {},
  barStyle = { backgroundColor: "#2096D3" },
  value = 0,
}) => {
  const BorderLinearProgress = withStyles({
    root: {
      height: 20,
      backgroundColor: lighten(
        get(barStyle, "backgroundColor", "#2096D3"),
        0.5
      ),
      borderRadius: 20,
    },
    bar: {
      ...barStyle,
      borderRadius: 20,
      backgroundColor: get(barStyle, "backgroundColor", "#2096D3"),
    },
  })(LinearProgress);

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    margin: {
      margin: theme.spacing(1),
    },
  }));

  const classes = useStyles();

  return (
    <div data-testid="WC-PROGRESS-LIVE" style={{ ...style, ...properties }}>
      <BorderLinearProgress
        className={classes.margin}
        variant="determinate"
        color="secondary"
        value={value}
      />
    </div>
  );
};

const ProgressPlugin: React.FC<PluginProps> = ({ settings }) => {
  const properties = convertProperties(settings.properties);
  return (
    <V1ObjectWrapper settings={settings}>
      <Progress {...settings} properties={properties} />
    </V1ObjectWrapper>
  );
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.PROGRESS
  ) {
    return [ProgressPlugin];
  }
};

export default Progress;
