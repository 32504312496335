import { EditorMode } from "@sc/modules/v2/Editor/types";
import carouselItemSettings from "./settings";

export const addNewCarouselItem = ({
  settings = { items: [] },
  pageContent = [],
  updateContent = () => null,
  updateComponentSettings = () => null,
}) => {
  // deactivate carousel, clone last one, and make active
  const newId = Math.random().toString(36).slice(2);
  const items = [
    ...settings.items.map((itm) => ({
      ...itm,
      active: false,
    })),
    {
      ...settings.items[settings.items.length - 1],
      id: newId,
      label: `Slide ${settings.items.length}`,
      active: true,
    },
  ];

  const key = pageContent.findIndex((itm) => itm.id === settings.id);
  const newContent = [
    ...pageContent.slice(0, key + 1),
    {
      ...carouselItemSettings.default.children[0],
      itemId: newId,
      parent: settings.id,
      id: newId,
    },
    ...pageContent.slice(key + 1),
  ];

  // console.log({ pageContent, newContent, settings, key });
  updateContent(newContent, true, false, () => {
    // console.log("Content Updated");
    updateComponentSettings(
      settings.id,
      { ...settings, items },
      true,
      false
      // () => {
      // console.log("Carousel Updated");
      // }
    );
  });
};

export const switchCarouselItem = ({
  settings = { id: "", items: [] },
  updateComponentSettings = () => null,
  carouselItemId = "",
  mode = EditorMode.LIVE,
}) => {
  console.log("Switching to", carouselItemId, mode);
  const items = settings.items.map((itm) => ({
    ...itm,
    active: itm.id === carouselItemId,
  }));
  if (mode === EditorMode.EDITOR)
    updateComponentSettings(settings.id, { ...settings, items }, true, false);
  else updateComponentSettings(settings.id, { ...settings, items });
};
